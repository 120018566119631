
import { defineComponent, ref } from 'vue'
import ProductFinder from '@/components/ProductFinder.vue'
import { Product } from '@/types'

export default defineComponent({
  name: 'POS',
  components: {
    ProductFinder
  },
  setup () {
    const host = process.env.VUE_APP_HOST
    const currentProduct = ref<Product|null>()
    const amounts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 15, 20, 32, 48]
    const toast = ref<string>('')

    function setProduct (product: Product) {
      currentProduct.value = product
    }

    function setAmount (amount: number) {
      window.setTimeout(() => {
        currentProduct.value = null
        toast.value = 'Wareneingang erfasst!'

        window.setTimeout(() => {
          toast.value = ''
        }, 1000)
      }, 500)
    }

    return {
      setProduct,
      currentProduct,
      host,
      amounts,
      toast,
      setAmount
    }
  }
})
