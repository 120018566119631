<template>
  <div class="container">

    <div class="toast" v-if="toast">
      <span>
        {{toast}}
      </span>
    </div>

    <h1>Wareneingang erfassen</h1>

    <h3>Produkte auswählen</h3>
    <ProductFinder @select="setProduct" />

    <div class="entry" v-if="currentProduct">
        <div class="preview">
          <img :src="host + currentProduct.image" v-if="currentProduct.image" />
          {{ currentProduct.name }}<br />
          {{ currentProduct.variant }}
        </div>

        <div  class="amount">
          <ul>
            <li v-for="amount in amounts" :key="amount" @click="setAmount">
              <span>{{amount}}</span>
            </li>
          </ul>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import ProductFinder from '@/components/ProductFinder.vue'
import { Product } from '@/types'

export default defineComponent({
  name: 'POS',
  components: {
    ProductFinder
  },
  setup () {
    const host = process.env.VUE_APP_HOST
    const currentProduct = ref<Product|null>()
    const amounts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 15, 20, 32, 48]
    const toast = ref<string>('')

    function setProduct (product: Product) {
      currentProduct.value = product
    }

    function setAmount (amount: number) {
      window.setTimeout(() => {
        currentProduct.value = null
        toast.value = 'Wareneingang erfasst!'

        window.setTimeout(() => {
          toast.value = ''
        }, 1000)
      }, 500)
    }

    return {
      setProduct,
      currentProduct,
      host,
      amounts,
      toast,
      setAmount
    }
  }
})
</script>

<style lang="scss" scoped>
  .toast {
    position: fixed;
    top: 250px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    span {
      display: block;
      border-radius: 5px;
      background-color: green;
      padding: 10px 50px;
      color: white;
    }
  }

  .entry {
    display: flex;
  }

  .preview {
    padding-top: 50px;
    width: 20%;
    margin: 0 auto;
  }

  .amount {
    width: 80%;
    padding-left: 20px;
    ul {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;

      li {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        width: 33%;
        border: 1px solid #eee;
        background-color: #aaa;
        cursor: pointer;
        height: 75px;
        font-size: 3rem;
      }
    }
  }
</style>
